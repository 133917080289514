
import { computed, defineComponent, ref } from "vue";
import { useEncryption } from "@/composables/useEncryption";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  onIonViewWillEnter
} from "@ionic/vue";
import { closeOutline, backspaceOutline } from "ionicons/icons";
import { useStorage } from "@/composables/useStorage";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCard,
    IonRow,
    IonCol,
    IonButton,
    IonIcon
  },
  emits: ["success"],
  async setup() {
    const pin = ref("");
    const mode = ref();
    const nums = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9]
    ];
    const first = ref("");
    const message = ref("");

    const { comparePin, encrypt } = useEncryption();
    const { setPin, pinIsSetup } = useStorage();
    const router = useRouter();

    onIonViewWillEnter(async () => {
      mode.value = (await pinIsSetup()) ? "validate" : "create";
    });

    const title = computed(() => {
      if (mode.value === "create") {
        return first.value.length ? "Re-type Pin" : "Create Pin";
      }
      return "Enter Pin";
    });

    const clearClicked = () => {
      pin.value = "";
      first.value = "";
      message.value = "";
    };

    const handleCorrectPin = () => {
      first.value = "";
      pin.value = "";
      router.push({
        name: "New"
      });
    };

    const handlePinEntered = async () => {
      if (mode.value === "create") {
        if (first.value.length) {
          if (pin.value === first.value) {
            await setPin(encrypt(pin.value));
            handleCorrectPin();
          } else {
            message.value = "Pins don't match";
            return;
          }
        } else {
          first.value = pin.value;
          pin.value = "";
          return;
        }
      } else {
        const correctPin = await comparePin(pin.value);
        if (correctPin) {
          handleCorrectPin();
        } else {
          message.value = "Incorrect Pin";
        }
      }
    };

    const handleForgottenPinClick = () => {
      router.push({ name: "resetPin" });
    };

    const backClicked = () => {
      if (pin.value.length !== 0) {
        pin.value = pin.value.substr(0, pin.value.length - 1);
      }
      message.value = "";
    };
    const numClicked = (val: number) => {
      if (pin.value.length < 4) {
        pin.value += val;
        if (pin.value.length === 4) {
          handlePinEntered();
        }
      }
    };

    return {
      backspaceOutline,
      closeOutline,
      message,
      mode,
      nums,
      pin,
      title,
      backClicked,
      clearClicked,
      handleForgottenPinClick,
      numClicked
    };
  }
});
