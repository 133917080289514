import CryptoJS from 'crypto-js';
import { useStorage } from '@/composables/useStorage'

export function useEncryption() {
    const encrypt = (string: string) => {
        return CryptoJS.AES.encrypt(string, 'secret key 123').toString();
    }
    const decrypt = (string: string) => {
        const bytes = CryptoJS.AES.decrypt(string, 'secret key 123');
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    const comparePin = async (enteredPin: string) => {
        const { getPin } = useStorage();
        const pin = (await getPin()).value;
        if (pin) {
            return enteredPin === decrypt(pin);
        }
        return false;
    }
    return { comparePin, encrypt, decrypt }
}

