import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40fe846f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "messageContainer" }
const _hoisted_3 = { class: "pinMessage" }
const _hoisted_4 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.mode === 'validate')
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          class: "forgottenPin",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleForgottenPinClick()))
                        }, " Forgotten Pin? "))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card, { class: "pinInput" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.message), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(['dot', _ctx.pin.length >= 1 ? 'filled' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(['dot', _ctx.pin.length >= 2 ? 'filled' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(['dot', _ctx.pin.length >= 3 ? 'filled' : ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(['dot', _ctx.pin.length >= 4 ? 'filled' : ''])
            }, null, 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_card, { class: "pinCard" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nums, (row) => {
              return (_openBlock(), _createBlock(_component_ion_row, {
                class: "pinRow",
                key: row
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (val) => {
                    return (_openBlock(), _createBlock(_component_ion_col, { key: val }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          class: "pinButton",
                          expand: "block",
                          onClick: ($event: any) => (_ctx.numClicked(val))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(val), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_ion_row, { class: "pinRow" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      class: "pinButton",
                      expand: "block",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearClicked()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          class: "pinAction",
                          icon: _ctx.closeOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      class: "pinButton",
                      expand: "block",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.numClicked(0)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("0")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      class: "pinButton",
                      expand: "block",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.backClicked()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          class: "pinAction",
                          icon: _ctx.backspaceOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}